import { render, staticRenderFns } from "./ServerReactivationResult.vue?vue&type=template&id=4a258014&scoped=true&"
import script from "./ServerReactivationResult.vue?vue&type=script&lang=ts&"
export * from "./ServerReactivationResult.vue?vue&type=script&lang=ts&"
import style0 from "./ServerReactivationResult.vue?vue&type=style&index=0&id=4a258014&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a258014",
  null
  
)

export default component.exports