
import { Component, Vue } from "vue-property-decorator";
import { ContentNode } from "@/types/layout/contentNode";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";
import CloudActivationBackground from "@/components/landingPages/cloudActivation/CloudActivationBackground.vue";
import logger from "@/util/logger";
import { CloudFreeReactivationResponse, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "ServerReactivationResult",
  components: {
    SimpleIconTitle,
    CloudActivationBackground,
  },
})
export default class ServerReactivationResult extends Vue {
  cloudActivationData!: CloudFreeReactivationResponse;
  serverData: Array<ContentNode> = [];
  hasError = false;
  reactivateInterval!: number;
  errorMessage = "Please try again.";

  created() {
    this.reactivateAndCheckData();
    this.reactivateInterval = setInterval(() => {
      this.reactivateAndCheckData();
    }, 20000);
  }

  reactivateAndCheckData() {
    logger.info("checking status");
    const token = this.$route.query.token as string;
    this.$jfCloudActivations.reactivate({ token });
    this.cloudActivationData = this.$jfCloudActivations.getCloudFreeReactivationData() as CloudFreeReactivationResponse;
    this.redirectIfReady();
    this.fillData();
  }

  fillData() {
    logger.info(JSON.stringify(this.cloudActivationData));
    switch (this.cloudActivationData.result) {
      case storeErrorsMapping.cloudReactivation.serverDeleted:
        this.hasError = true;
        this.errorMessage = "server deleted";
        break;
      case storeErrorsMapping.cloudReactivation.tokenIsInvalid:
        this.hasError = true;
        this.errorMessage = "invalid token";
        break;
    }
    if (this.hasError) {
      clearInterval(this.reactivateInterval);
      return;
    }
    this.serverData = [
      {
        title: "Server Name",
        value: this.cloudActivationData?.serverName,
        icon: "activation_server.svg",
      },
      {
        title: "Location",
        value: this.cloudActivationData?.cloudProviderCode + " in " + this.cloudActivationData?.regionName,
        icon: "activation_location.svg",
      },
    ];
  }
  redirectIfReady() {
    if (this.cloudActivationData.ready) {
      window.location.replace(this.cloudActivationData.instanceUrl);
    }
  }
  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }
}
